// 存放数据类型
const classType = {};
// 数据类型集合
const typeString = 'Boolean Number String Function Array Date RegExp Object Error';
// 生成classType映射
typeString.split(' ').map(function (item, index) {
  classType['[object ' + item + ']'] = item.toLowerCase();
});
// 判断数据类型(这里不使用typeof)
function type(obj) {
  if (obj === null) {
    return obj + '';
  }
  return typeof obj === 'object' || typeof obj === 'function'
    ? classType[Object.prototype.toString.call(obj)] || 'object'
    : typeof obj;
}
// 判断是否为函数
export function isFunction(obj) {
  return type(obj) === 'function';
}
// 组件映射表
export const ElementMapping = {
  input: {
    // 对应到 Element 中的组件 tag
    component: 'el-input',
    // 传递给 Element 组件的默认 props
    props: {
      clearable: true,
      placeholder: '请选择'
    }
  },
  select: {
    component: 'el-select',
    props: {
      clearable: true,
      placeholder: '请选择'
    }
  },
  dicSelect: {
    component: 'dict-select'
  },
  // 坐落指令
  zl: {
    component: 'zl-directives'
  },
  // raido组渲染父级使用div
  radio: {
    component: 'el-radio-group',
    props: {}
  },
  switch: {
    component: 'el-switch',
    props: {}
  },
  chooseBdcqzh: {
    component: 'choose-bdcqzh',
    props: {}
  },
  date: {
    component: 'el-date-picker',
    props: {
      type: 'date',
      format: 'yyyy-MM-dd',
      valueFormat: 'timestamp'
    }
  }
};
