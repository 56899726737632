<template>
  <div class="main" v-if="visible">
    <el-form ref="form" class="form" :model="formData" label-width="170px">
      <div style="font-size: 0px" v-for="(formItems, index) in formItemsArray" :key="index">
        <div
          class="grid"
          v-if="!formItems.showCallback || (formItems.showCallback && formItems.showCallback(formData))"
        >
          <el-row
            class="tablerow tablerowtitle"
            v-if="
              (formItems.title && !formItems.showCallback) ||
              (formItems.showCallback && formItems.showCallback(formData))
            "
          >
            <div class="table-title-row">
              <h5>
                <span class="span">{{ formItems.title }}</span>
              </h5>
            </div>
          </el-row>
          <el-row
            class="rowdisplay tablerow flex-center-align"
            :class="
              (formItems.title && getAllTableColumnConfig.includes(formItems.title)) || !formItems.title
                ? 'background-AA'
                : ''
            "
            justify="space-between"
          >
            <slot :title="formItems.title"></slot>
            <template v-for="(input, i) in formItems.value">
              <el-col v-if="input._ifRender" :key="input.key + '_' + i" :span="input.span" style="float: left">
                <el-form-item v-if="input._ifRender" :prop="input.key" :label="input.title" :rules="input.rule">
                  <template v-if="input.tag == 'choose-bdcqzh'">
                    <choose-bdcqzh
                      :bdcqzhData="formData[input.key]"
                      :bdcqzhConfig="{
                        rules: input.rule,
                        mbanType: input.props.mbanType,
                        disabled: disabled || input.props.disabled,
                      }"
                      @update:bdcqzhData="(val) => updataBdcqzh(val)"
                    ></choose-bdcqzh>
                  </template>
                  <template v-else-if="input.tag == 'zl-directives'">
                    <zl-directives
                      :zlData="formData"
                      :zlConfig="{ disabled: disabled || input.props.disabled }"
                    ></zl-directives>
                  </template>
                  <template v-else-if="input.tag == 'el-radio-group'">
                    <el-radio-group
                      v-model="formData[input.key]"
                      :disabled="disabled || input.props.disabled"
                      @change="input.change && input.change(formData)"
                    >
                      <el-radio v-for="(item, i) in input.props.options" :key="i" :label="item.value">{{
                        item.label
                      }}</el-radio>
                    </el-radio-group>
                  </template>
                  <component
                    v-else
                    :is="input.tag"
                    v-model="formData[input.key]"
                    @change="input.change && input.change(formData)"
                    @input="input.input && input.input(formData)"
                    v-bind="input.props || {}"
                    :disabled="disabled || input.props.disabled"
                  >
                    <!-- 渲染select option -->
                    <template v-if="input.tag === 'el-select'">
                      <el-option
                        v-for="item in input.props.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </component>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
/**
 * 基础动态表单
 *
 * @description
 * 核心逻辑为动态表单计算项，核心方法为computeFormItem
 * 如需改变样式，可写样式组件，直接将该组件的方法引入过去
 */
import { ElementMapping, isFunction } from './utils';
export default {
  name: 'Index',
  props: {
    formConfig: {
      type: Array,
      default: () => [],
    },
    keyName: {
      type: String,
      default: () => '',
    },
    formModel: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    getAllTableColumnConfig: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {},
      visible: false,
    };
  },
  created() {
    this.formData = {};
    // console.log(this.formConfig, 'this.formConfig');

    this.formConfig.map((cur) => {
      const acc = this.computeFormData(cur.value, this.formModel);
      for (const key in acc) {
        this.$set(this.formData, key, acc[key]);
      }
    });
    this.visible = true;
  },
  computed: {
    // 动态计算需要循环的表单项
    formItemsArray() {
      let data = [];
      // this.formConfig.forEach((element) => {
      //   data.push(element);
      // });
      // data.map((item) => {
      //   item.value = item.value.map((item) => {
      //     return this.computeFormItem(item, this.formData);
      //   });
      // });
      this.formConfig.forEach((item) => {
        data.push(item);
        data[data.length - 1].value.forEach((pitem, index) => {
          data[data.length - 1].value[index] = this.computeFormItem(pitem, this.formData);
        });
      });
      return data;
    },
  },
  watch: {
    formData: {
      handler(newValue) {
        const params = this.keyName ? { key: this.keyName, value: newValue } : newValue;
        this.$emit('setData', params);
      },
      deep: true,
    },
    formModel: {
      handler(newValue) {
        this.setFormData(newValue);
      },
      deep: true,
    },
  },
  methods: {
    updataBdcqzh(val) {
      this.formData.bdcqzh = val;
      // this.$refs.form.validdate((valid) => {});
    },
    setFormData(formModel) {
      this.formData = {};
      this.formConfig.map((cur) => {
        const acc = this.computeFormData(cur.value, formModel);
        for (const key in acc) {
          this.$set(this.formData, key, acc[key]);
        }
      });
      this.visible = true;
    },
    computeFormItem(config, form) {
      // 返回结构体
      const item = { ...config };
      // 表单控件的类型
      const type = item.type || 'input';
      // 对应到组件映射表
      const def = ElementMapping[type];
      item.tag = def.component;
      item.props = Object.assign({}, def.props, item.props);
      // 获取动态 props
      if (isFunction(item.getProps)) {
        Object.assign(item.props, item.getProps(form));
      }
      // 获取动态标题
      if (isFunction(item.title)) {
        item.title = item.title(form);
      }
      // 设置动态验证
      // if (isFunction(item.rule)) {
      //   item.rule = item.rule(form);
      // }
      //有特殊规则验证;
      if (item.ruleFunction) {
        item.rule = item.ruleFunction(this.formData);
      }
      //值计算
      if (item.computedFn) {
        form[item.key] = item.computedFn(this.formData);
      }
      // 条件渲染
      item._ifRender = isFunction(item.ifRender) ? !!item.ifRender(form) : true;
      // 是否占位
      item._ifPlaceHolder = item._ifRender ? true : !item.isNotPlaceHolder;
      // 设置宽度
      item.span = item.span || 11;
      //默认值
      if (!form[item.key] && form[item.key] !== 0) {
        form[item.key] = item.value || '';
      }
      if (item.input) {
        item.input(form);
      }
      //数值类型 默认两位小数
      if (item.props.zslx && (form[item.key] || form[item.key] === 0)) {
        form[item.key] = this.getFloat2(form[item.key]);
      }
      //价格类型装换大写汉字
      if (item.props.zwdx && (form[item.key] || form[item.key] === 0)) {
        form.zwdx = this.moneyToChinese(form[item.key]);
        this.$set(this.formData, 'zwdx', this.moneyToChinese(form[item.key]));
      }

      // 防止表单提交时存在多余 key
      // if (!item._ifRender) {
      //   delete form[item.key];
      // }
      // form-item 配置
      return item;
    },
    //初始化数据
    computeFormData(formConfig, form) {
      // console.log(formConfig, form, 'form');

      let formObject = Object.assign({}, form);
      formConfig.map((item) => {
        //布尔值处理
        if (typeof formObject[item.key] === 'boolean') {
          formObject[item.key] = formObject[item.key] + '';
        }
        //0值处理
        if (formObject[item.key] === 0) {
          formObject[item.key] = '0';
        }
        if (!formObject[item.key]) {
          formObject[item.key] = item.value || '';
        } else {
          formObject[item.key] = formObject[item.key] + '';
        }
      });
      return formObject;
    },
    setData(data) {
      this.visible = false;
      this.formConfig.map((cur) => {
        const acc = this.computeFormData(cur.value, data);
        for (const key in acc) {
          this.$set(this.formData, key, acc[key]);
        }
      });
      this.visible = true;
    },
    //获取数据
    getData() {
      return this.formData;
    },
    // 重置字段
    resetFields() {
      this.$refs.form.resetFields();
    },
    moneyToChinese(n) {
      if (!n) {
        return;
      }
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
        return '数据非法';
      }
      let unit = '千百拾亿千百拾万千百拾元角分';
      let str = '';
      n += '00';
      var p = n.indexOf('.');
      if (p >= 0) {
        n = n.substring(0, p) + n.substr(p + 1, 2);
      }
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      }
      const a = str
        .replace(/零(千|百|拾|角)/g, '零')
        .replace(/(零)+/g, '零')
        .replace(/零(万|亿|元)/g, '$1')
        .replace(/(亿)万|壹(拾)/g, '$1$2')
        .replace(/^元零?|零分/g, '')
        .replace(/元$/g, '元整');
      return a;
    },
    getFloat2(x) {
      if (x != '.') {
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      } else {
        return '0.00';
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/common-variables';
.form {
  //margin-top: 26px;
}
.grid {
  display: grid;
}
.background-AA {
  background-color: #ffffff;
  //background-color: #f6f6f6;
}
.rowdisplay {
  display: inline-block;
  width: 100%;
  padding: $spacing-base $spacing-medium;
  .el-col {
    margin: $spacing-base 0;
    /deep/ .el-form-item {
      margin: 0;
      .el-form-item__content {
        line-height: 28px !important;
        .el-input__inner {
          background-color: white !important;
        }
      }
    }
  }
}
.table-title {
  padding: 10px 0 10px 10px;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  h5 {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      height: 3px;
      width: 108%;
      bottom: -10px;
      left: -7%;
      background-color: $color-primary;
    }
  }
}
.tablerowtitle {
  border-bottom: 0px;
  border-right: 1px solid #c6d5ea;
  border-left: 1px solid #c6d5ea;
  background-color: #f2f6fc;
}
.tablerow {
  display: inline-block;
  width: 100%;
  padding: 0px;
  border-top: 1px solid #c6d5ea;
  //border-bottom: 1px solid #c6d5ea;
  //border-left: 1px solid #c6d5ea;
  border-right: 1px solid #c6d5ea;
  .el-col:last-child {
    border-top: 1px solid #c6d5ea;
  }
  .el-col {
    margin: 0px;
    //border-top: 1px solid #c6d5ea;
    border-left: 1px solid #c6d5ea;
    border-bottom: 1px solid #c6d5ea;
    //border-right: 1px solid #c6d5ea;
    /deep/ .el-form-item {
      margin: 0;
      .el-form-item__content {
        line-height: 28px !important;
        border-left: 1px solid #c6d5ea;
      }
    }
    /deep/ .el-input {
      z-index: 2;
      background-color: rgba(0, 0, 0, 0);
    }
    /deep/ .el-form-item.is-error .el-input__inner {
      border: 1px solid red;
      background-color: rgba(0, 0, 0, 0);
    }
    /deep/.el-form-item--small .el-form-item__error {
      padding-top: 2px;
      position: absolute;
      top: 8px;
      right: 10px;
      text-align: right;
      z-index: 1;
    }
  }
  /deep/ .el-radio-group {
    height: 32px;
  }
  /deep/.el-radio {
    line-height: 30px;
    margin-left: 10px;
  }
  /deep/.el-input__inner {
    border: none;
    color: #000;
    font-weight: 500;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #fafafa;
    border-color: #e4e7ed;
    color: #000;
    font-weight: 500;
    cursor: not-allowed;
  }
  /deep/.el-radio__input.is-disabled + span.el-radio__label {
    color: #000;
  }
  /deep/ .el-radio__label {
    color: #000;
  }
  /deep/.el-form-item__label {
    font-weight: bold;
    text-align: right;
    background-color: #f4f9fd;
    color: #333;
    &:before {
      color: #fe0000 !important;
    }
  }
  .table-title-row {
    margin-bottom: 0px;
    text-align: left;
    h5 {
      padding: 15px;
      font-size: 14px;
      font-weight: 600;
      .span {
        padding-left: 10px;
        border-left: 4px solid #2c8bff;
      }
    }
  }
}
//.rowdisplay {
//  width: 100%;
//  display: inline-block;
//  background-color: #f6f6f6;
//  padding: $spacing-base $spacing-medium;
//  margin-bottom: $spacing-medium;
//  .el-col {
//    margin: $spacing-base 0;
//    .el-form-item {
//      margin: 0;
//      .el-form-item__content {
//        line-height: 29px !important;
//      }
//    }
//  }
//}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-row--flex {
  flex-wrap: wrap;
}
/deep/ .el-select.el-select--small.w36 {
  width: 36%;
}
/deep/ .el-input.w80,
/deep/ .el-select.w80,
/deep/ .el-date-editor.el-input__inner.w80 {
  width: 80%;
}
/deep/ .el-col-24 {
  /deep/ .el-input {
    width: 100%;
  }
}
/deep/ .el-date-editor.el-input {
  width: 100%;
}
/deep/.el-input__inner:focus::-webkit-input-placeholder {
  color: transparent;
}
/* Firefox < 19 */
/deep/.el-input__inner:focus:-moz-placeholder {
  color: transparent;
}
/* Firefox > 19 */
/deep/.el-input__inner:focus::-moz-placeholder {
  color: transparent;
}

/* Internet Explorer 10 */
/deep/.el-input__inner:focus:-ms-input-placeholder {
  color: transparent;
}
</style>
